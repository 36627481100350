
  import { Component } from 'vue-property-decorator'
  import Cell from './cell'
  import { LeadActivity } from '@/entities/crm'

@Component
  export default class AlertCell extends Cell {
  open: boolean = false
  activities: LeadActivity[] = []

  get headers () {
    return [
      { text: '', value: 'result', width: 40, align: 'center', sortable: false },
      { text: 'Creado', value: 'createDate', width: 80, align: 'center' },
      { text: 'Acción a realizar', value: 'action', width: 190, align: 'center' },
      { text: 'Vencimiento', value: 'timeStart', width: 120, align: 'center', sortable: false },
      { text: 'Nota compra', value: 'note', width: 300, align: 'center' },
      { text: 'Nota venta', value: 'noteSell', width: 300, align: 'center' },
    ]
  }

  get activitiesFormatted () {
    return this.activities.map((act: LeadActivity) => {
      const result = act.closingReason?.type?.name
      const createDate = act.created
      const timeStart = act.schedulingDateLocalTime.format('DD/MM/YYYY HH:mm')
      const note = act.notes?.find(note => note.deal?.type?.name === 'purchase')?.note || ''
      const noteSell = act.notes?.find(note => note.deal?.type?.name === 'sale')?.note || ''
      const action = act.type?.description || ''
      const icon = act.type?.icon?.main || 'mdi-information'
      const iconColor = act.type?.color || '#000'

      return {
        result,
        createDate,
        icon,
        iconColor,
        action,
        timeStart,
        note,
        noteSell,
      }
    })
  }

  async openHistory () {
    this.activities = await this.fetchData({
      query: { name: 'find', model: 'LeadActivity', order: { id: 'desc' } },
      filter: {
        id_lead: { _eq: this.item.id },
      },
      force: true,
    })
    this.open = true
  }

  getColor (item) {
    const { result } = item

    if (!result) {
      return 'orange'
    }

    return result === 'successful' ? 'green' : 'red'
  }

  getIcon (item) {
    const { result } = item

    if (!result) {
      return 'mdi-clock-outline'
    }

    return result === 'successful' ? 'mdi-check-circle-outline' : 'mdi-close-circle-outline'
  }

  getIsValidDate (date) {
    return !date.includes('1990')
  }
  }
